import $util from "@/utils/util";

const QUERY_DEVICE_DETAIL = "QUERY_DEVICE_DETAIL";
const QUERY_DEVICE_SUCCESS = "QUERY_DEVICE_SUCCESS";
const QUERY_DEVICE_FAILED = "QUERY_DEVICE_FAILED";
const SET_ADD_NODE_MODAL_VISIBLE = "SET_ADD_NODE_MODAL_VISIBLE";
const DEVICES_SAVE = "DEVICES_SAVE";
const CHECK_SN_SUCCESS = "CHECK_SN_SUCCESS";
const CHECK_SN_FAILED = "CHECK_SN_FAILED";
const CHECK_SN = "CHECK_SN";

const constants = {
  QUERY_DEVICE_DETAIL,
  QUERY_DEVICE_SUCCESS,
  QUERY_DEVICE_FAILED,
  SET_ADD_NODE_MODAL_VISIBLE,
  DEVICES_SAVE,
  CHECK_SN_SUCCESS,
  CHECK_SN_FAILED,
  CHECK_SN,
};

function success(data) {
  return {
    payload: data,
    type: QUERY_DEVICE_SUCCESS,
  };
}

function checkSNSuccess(data) {
  return {
    payload: data,
    type: CHECK_SN_SUCCESS,
  };
}

function checkSNFailed() {
  return {
    type: CHECK_SN_FAILED,
  };
}

function failed() {
  return {
    type: QUERY_DEVICE_FAILED,
  };
}

function setAddNodeModalVisible(visible) {
  return {
    payload: { addNodeModalVisible: visible },
    type: SET_ADD_NODE_MODAL_VISIBLE,
  };
}

function save(data) {
  return {
    payload: data,
    type: DEVICES_SAVE,
  };
}

function queryDeviceDetail(device_id) {
  return (dispatch, getState) => {
    const uri = `/api/miner_manage/miner/getMinerDetail?device_id=${device_id}`;
    $util.doGetFetch({
      uri,
      successCallback: (data) => {
        dispatch(success(data));
      },
      failedCallback: (code, message) => {
        dispatch(failed());
      },
    });
  };
}

function checkSNBeforeJump(device_id, callback) {
  return (dispatch, getState) => {
    const uri = `/api/miner_manage/miner/getInfoCheckStatus?device_id=${device_id}`;
    dispatch({
      type: CHECK_SN,
    });
    $util.doGetFetch({
      uri,
      successCallback: (data) => {
        callback && callback(data);
        dispatch(checkSNSuccess(data));
      },
      failedCallback: (code, message) => {
        dispatch(checkSNFailed());
      },
    });
  };
}

const actions = {
  queryDeviceDetail,
  setAddNodeModalVisible,
  checkSNBeforeJump,
  save,
};

export { actions, constants };
