import { constants } from "../actions/devices";

const defaultState = {
  deviceDetail: {},
  loading: false,
  addNodeModalVisible: false,
  editDeviceID: "",
  deviceAction: "add",
  checkSNLoading: false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case constants.QUERY_DEVICE_DETAIL:
      return Object.assign({}, state, {
        loading: true,
      });
    case constants.QUERY_DEVICE_SUCCESS:
      return Object.assign({}, state, {
        ...action.payload,
        loading: false,
      });
    case constants.QUERY_DEVICE_FAILED:
      return Object.assign({}, state, {
        loading: false,
      });
    case constants.CHECK_SN:
      return {
        ...state,
        checkSNLoading: true,
      };
    case constants.CHECK_SN_SUCCESS:
      return {
        ...state,
        checkSNLoading: false,
        // sn校验成功之后需要关闭模态框
        addNodeModalVisible: false,
      };
    case constants.CHECK_SN_FAILED:
      return {
        ...state,
        checkSNLoading: false,
      };
    case constants.SET_ADD_NODE_MODAL_VISIBLE:
    case constants.DEVICES_SAVE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
