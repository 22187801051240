import React, { Component } from "react";
import { Router, browserHistory } from "react-router";
import { Provider } from "react-redux";
import store from "@/store";
import routes from "@/routes/Index";

// const Second2 = () => (
//   <div>
//     <h2>Second2</h2>
//   </div>
// );

class App extends Component {
  state = {};

  componentDidMount = () => {};

  render() {
    return (
      <Provider store={store}>
        <Router history={browserHistory}>{routes}</Router>
      </Provider>
    );
  }
}

export default App;
