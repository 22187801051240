import $util from "@/utils/util";

const GET_USER_BID = "GET_USER_BID";
const USER_SAVE = "USER_SAVE";

const constants = {
  GET_USER_BID,
  USER_SAVE,
};

function save(data) {
  return {
    payload: data,
    type: USER_SAVE,
  };
}

function getUserBid() {
  return (dispatch, getState) => {
    const uri = "/api/auth/getbindid";
    $util.doGetFetch({
      uri,
      successCallback: (data) => {
        dispatch(
          save({
            bid: data?.bid || "",
          })
        );
      },
    });
  };
}

const actions = {
  getUserBid,
  save,
};

export { actions, constants };
