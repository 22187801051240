import React from 'react';
import { Route, IndexRedirect, browserHistory } from 'react-router';
import $util from '@/utils/util';
// import $pars from '@/utils/pars';
import asyncComponent from '@/components/base/asyncComponent';

const init = (nextState, replace, cb) => {
  $util.judgeRedirectToBdfangzhou();
  //设置ajax根域名
  $util.setAjaxRootDomain();
  // console.log(1);
  cb();
};

//进入登录
const enterLogin = (nextState, replace, cb) => {
  const { forced } = nextState.location.query;
  // console.log('enterLogin: ', forced);
  if (!forced) {
    let account = $util.getLoginStatus();
    // console.log(account);
    if (account) {
      browserHistory.push('/mill/list');
    } else {
      cb();
    }
  } else {
    cb();
  }
};

// const LogIn = (<div>logIn</div>);
// const Second2 = () => (
//   <div>
//     <h2>Second2</h2>
//   </div>
// );

//首次进入控制台的
const enterFrameWork = (nextState, replace, cb) => {
  // let hasLoginStatus = checkLoginStatus(replace, cb);
  // // console.log(hasLoginStatus);
  // if (!hasLoginStatus) {
  //     //没有登录态的话 指到登录页面 就不用走后面的请求了
  //     return false;
  // };
  // cb();
  let promiseList = [];
  // //获取运营商 省份等映射
  let queryLocationParsPromise = $util.queryLocationPars();
  let querySuggestionConfigPromise = $util.querySuggestionConfig();
  promiseList.push(queryLocationParsPromise);
  promiseList.push(querySuggestionConfigPromise);
  Promise.all(promiseList).then(function (result) {
    cb();
  });
};

//检查登录态
// const checkLoginStatus = (replace, cb) => {
//     let userCookie = $util.getCookie("user");
//     //如果没有登录态cookie 则跳转到login页面
//     if (!userCookie) {
//         replace({ pathname: '/login' });
//         cb();
//         return false;
//     };
//     if (userCookie) {
//         $pars.userInfo.user = userCookie;
//     };
//     return true;
// };

const Login = asyncComponent(() => import('@/template/Login'));
const Register = asyncComponent(() => import('@/template/Register'));
const Lemon = asyncComponent(() => import('@/template/Lemon'));
const FrameWork = asyncComponent(() => import('@/template/FrameWork'));
const Index = asyncComponent(() => import('@/template/Index'));
const Overview = asyncComponent(() => import('@/template/Overview'));
const CommonView = asyncComponent(() => import('@/layouts/Layout'));

// const MillView = asyncComponent(() => import("@/template/mill/View"));
const MillOverview = asyncComponent(() => import('@/template/mill/Overview'));
const MillList = asyncComponent(() => import('@/template/mill/list/List'));
const MillDetail = asyncComponent(() => import('@/template/mill/list/Detail'));
const MillFault = asyncComponent(() => import('@/template/mill/fault/List'));

// const AccountView = asyncComponent(() => import("@/template/account/View"));
const AccountInfo = asyncComponent(() => import('@/template/account/Info'));
const AccountIdentify = asyncComponent(() =>
  import('@/template/account/IdentifyShow'),
);
// const PersonalQualifiy = asyncComponent(() => import("@/template/account/PersonalQualifiy"));
// const EnterpriseQualifiy = asyncComponent(() => import("@/template/account/EnterpriseQualifiy"));
const AccountResetPassword = asyncComponent(() =>
  import('@/template/account/ResetPassword'),
);

// const MessageView = asyncComponent(() => import("@/template/message/View"));
const SuggestList = asyncComponent(() => import('@/template/message/List'));
const SuggestDetail = asyncComponent(() => import('@/template/message/Detail'));

// const BillView = asyncComponent(() => import("@/template/bill/View"));
const BillInfo = asyncComponent(() => import('@/template/bill/Info'));
/*协议相关*/
const Cooperation1 = asyncComponent(() =>
  import('@/template/agreement/1.0.0/Cooperation'),
);
const Privacy1 = asyncComponent(() =>
  import('@/template/agreement/1.0.0/Privacy'),
);
const User1 = asyncComponent(() => import('@/template/agreement/1.0.0/User'));
const Cooperation = asyncComponent(() =>
  import('@/template/agreement/2.0.0/Cooperation'),
);
const Privacy = asyncComponent(() =>
  import('@/template/agreement/2.0.0/Privacy'),
);
const User = asyncComponent(() => import('@/template/agreement/2.0.0/User'));

const Error403 = asyncComponent(() =>
  import('@/template/error_page/NoAuthority403'),
);
const Error404 = asyncComponent(() =>
  import('@/template/error_page/NotFound404'),
);
const Error500 = asyncComponent(() =>
  import('@/template/error_page/ServerCrash500'),
);

const routes = (
  <Route path="/" onEnter={init}>
    {/*重定向 到myHome*/}
    <IndexRedirect to="/login" />

    {/*登录*/}
    <Route path="/login" component={Login} onEnter={enterLogin}></Route>
    {/*注册*/}
    <Route path="/register" component={Register}></Route>
    <Route path="/lemon" component={Lemon}></Route>

    {/*主框架*/}
    <Route path="/frameWork" component={FrameWork} onEnter={enterFrameWork}>
      <Route path="/index" component={Index}></Route>
      <Route path="/overview" component={Overview}></Route>
      {/* 设备相关 */}
      <Route path="/mill" component={CommonView}>
        <Route path="list" component={MillList}></Route>
        <Route path="overview" component={MillOverview}></Route>
        <Route path="detail" component={MillDetail}></Route>
        <Route path="fault" component={MillFault}></Route>
      </Route>
      {/*账号设置*/}
      <Route path="/account" component={CommonView}>
        <Route path="info" component={AccountInfo}></Route>
        <Route path="identify" component={AccountIdentify}></Route>
        {/* <Route path="personalQualifiy" component={PersonalQualifiy}></Route>
                <Route path="enterpriseQualifiy" component={EnterpriseQualifiy}></Route> */}
        <Route path="resetPassword" component={AccountResetPassword}></Route>
      </Route>
      {/*消息中心*/}
      <Route path="/message" component={CommonView}>
        <Route path="list" component={SuggestList}></Route>
        <Route path="detail" component={SuggestDetail}></Route>
      </Route>
      {/*我的账单*/}
      <Route path="/bill" component={CommonView}>
        <Route path="info" component={BillInfo}></Route>
      </Route>
    </Route>

    {/*协议页面*/}
    <Route path="/agreement/1.0.0/cooperation" component={Cooperation1}></Route>
    <Route path="/agreement/1.0.0/privacy" component={Privacy1}></Route>
    <Route path="/agreement/1.0.0/user" component={User1}></Route>
    <Route path="/agreement/cooperation" component={Cooperation}></Route>
    <Route path="/agreement/privacy" component={Privacy}></Route>
    <Route path="/agreement/user" component={User}></Route>

    {/*error 页面*/}
    <Route path="/error">
      {/*没有权限 403*/}
      <Route path="noAuthority" component={Error403}></Route>
      {/*没找到 404*/}
      <Route path="notFound" component={Error404}></Route>
      {/*服务器问题 500*/}
      <Route path="serverCrash" component={Error500}></Route>
    </Route>
    <Route path="*">
      <IndexRedirect to="/" />
    </Route>
  </Route>
);
export default routes;
