module.exports = {
    "ajaxDomain": "",
    // "ajaxDomain": "",
    "devAjaxDomain": "http://devapi-miner_manage_system.onethingpcs.com",
    "bdfangzhouDomain": "https://snodehome.cn",
    // "cookieDomain": ".digialcoin.com",
    //用户登录信息
    "userInfo": {
        "user": "",
    },
    "ispList": [],
    "ispMap": {},
    "provinceMap": {},
    "cityMap": {},
    "netTypeMap": {},
    "webPortMap": {},
    "bandTypeMap": {},
    "minerStatusMap": {},
    "submitMinerStatusMap": {
        "-1": "检测失败",
        "1" : "检测中",
    },
    "provinceCityOptions": [],
    "timeFormat": "YYYY-MM-DD HH:mm:ss",
    "dayFormat": "YYYY-MM-DD",
    "userType": {
        "0": "游客",
        "1": "个人",
        "2": "企业",
    },
    // 问题反馈配置
    suggestConfig: {},
    suggestClassifyMap: {},
    suggestTypeMap: {},
}

