import { message, notification } from "antd";
import { browserHistory } from "react-router";
// import React from 'react';
import $pars from "./pars";

// eslint-disable-next-line
// console.log($)
// console.log("aaa")
// console.log($pars);
// import moment from 'moment';

export default {
  /*
   * @Name 测试专用函数
   * @Param
   * @Return
   * @Author
   * @Readme
   */
  lemonTest: function () {
    console.log("lemon test");
    const args = {
      message: "提示",
      description:
        "I will never close automatically. I will be close automatically. I will never close automatically.",
      duration: 0,
    };
    notification.open(args);
  },

  /*
   * @Name 深度克隆
   * @Param
   * @Return
   * @Author
   * @Readme
   */
  deepClone: function (obj) {
    let newobj;
    if (typeof obj !== "object" || !obj) {
      return obj;
    } else {
      newobj = obj.constructor === Array ? [] : {};
      for (let i in obj) {
        newobj[i] =
          typeof obj[i] === "object" ? this.deepClone(obj[i]) : obj[i];
      }
    }
    return newobj;
  },

  /*
   * @Name 去掉字符串两边空格
   * @Param
   * @Return
   * @Author
   * @Readme
   */
  trimSpace(str) {
    if (str === undefined) {
      return false;
    }

    str = String(str);
    var rule = /(^\s*)|(\s*$)/g;
    var res = str.replace(rule, "");
    return res;
  },

  /*
   * @Name 生成随机数
   * @Param
   * @Return
   * @Author
   * @Readme 生成min~max的随机数
   */
  generateRandomNum: function (max, min) {
    if (!max || !min) {
      console.log("必须传入max和min");
      return false;
    }

    let num = Math.floor(Math.random() * (max - min + 1) + min);
    return num;
  },

  /*
   * @Name 写cookie
   * @Param
   * @Return
   * @Author
   * @Readme
   */
  setCookie: function (c_name, value, expiremMinutes, domain, path) {
    // console.log(path);
    var exdate = new Date();
    exdate.setTime(exdate.getTime() + expiremMinutes * 60 * 1000);
    document.cookie =
      c_name +
      "=" +
      escape(value) +
      (expiremMinutes ? ";expires=" + exdate.toGMTString() : "") +
      (domain ? ";domain=" + domain : "") +
      ";path=" +
      (path ? path : "/") +
      ";";
  },

  /*
   * @Name 读cookie
   * @Param
   * @Return
   * @Author
   * @Readme
   */
  getCookie: function (c_name) {
    if (document.cookie.length > 0) {
      var c_start = document.cookie.indexOf(c_name + "=");
      if (c_start !== -1) {
        c_start = c_start + c_name.length + 1;
        var c_end = document.cookie.indexOf(";", c_start);
        if (c_end === -1) c_end = document.cookie.length;
        return unescape(document.cookie.substring(c_start, c_end));
      }
    }
    return "";
  },

  /*
   * @Name 删除cookie
   * @Param
   * @Return
   * @Author
   * @Readme
   */
  delCookie: function (c_name, domain, path) {
    var exp = new Date();
    exp.setTime(exp.getTime() - 1);
    // console.log(exp)
    // console.log(exp.toGMTString())
    var cval = this.getCookie(c_name);
    if (cval !== null) {
      document.cookie =
        c_name +
        "=" +
        escape(cval) +
        ";expires=" +
        exp.toGMTString() +
        (domain ? ";domain=" + domain : "") +
        ";path=" +
        (path ? path : "/") +
        ";";
    }
  },

  /*
   * @Name 伪造登录态
   * @Param
   * @Return
   * @Author
   * @Readme
   */
  forgeLoginStatus: function () {
    let mySelf = this;
    //如果为本地环境 则模拟写入登录态cookie
    // console.log("伪造username");
    let pretendUserName = "lemon@qq.net";
    mySelf.setCookie("user", pretendUserName, 24 * 60); //1天
  },

  /*
   * @Name 获取前端存贮在cookie里的登录态
   * @Param
   * @Return
   * @Author
   * @Readme
   */
  getLoginStatus: function () {
    let mySelf = this;
    return mySelf.getCookie("account");
  },

  /*
   * @Name 删除前端存贮在cookie里的登录态
   * @Param
   * @Return
   * @Author
   * @Readme
   */
  delLoginStatus: function () {
    let mySelf = this;
    mySelf.delCookie("account");
  },

  /*
   * @Name 写入localStorage
   * @Param
   * @Return
   * @Author
   * @Readme
   */
  setLocalStorage: function (key, value) {
    window.localStorage.setItem(key, value);
  },

  /*
   * @Name 读取localStorage
   * @Param
   * @Return
   * @Author
   * @Readme
   */
  getLocalStorage: function (key) {
    return window.localStorage.getItem(key);
  },

  /*
   * @Name 删除localStorage
   * @Param
   * @Return
   * @Author
   * @Readme
   */
  delLocalStorage: function (key) {
    window.localStorage.removeItem(key);
  },

  /*
   * @Name JSON.parse
   * @Param
   * @Return
   * @Author
   * @Readme
   */
  /* eslint-disable */
  jsonParse: function (data, defaultValue) {
    var resData = "";
    var defaultValue = defaultValue ? defaultValue : false;
    try {
      var data = JSON.parse(data);
      resData = data;
    } catch (error) {
      resData = defaultValue;
      console.log(data + " JSON.parse失败，报错日志是: " + error);
    }
    return resData;
  },
  /* eslint-enable */

  /*
   * @Name JSON.stringify
   * @Param
   * @Return
   * @Author
   * @Readme
   */
  /* eslint-disable */
  jsonStringify: function (data, defaultValue) {
    var resData = "";
    var defaultValue = defaultValue ? defaultValue : false;
    try {
      var data = JSON.stringify(data);
      resData = data;
    } catch (error) {
      resData = defaultValue;
      console.log(data + " JSON.parse失败，报错日志是: " + error);
    }
    return resData;
  },
  /* eslint-enable */

  /*
   * @Name 判断obj是非空对象
   * @Param
   * @Return
   * @Author
   * @Readme
   */
  isNotEmptyObj(obj) {
    for (var i in obj) {
      if (obj.hasOwnProperty(i)) {
        // console.log(true) //不为空
        return true;
      }
    }
    // console.log(false) //为空
    return false;
  },

  /*
   * @Name 判断obj是空对象
   * @Param
   * @Return
   * @Author
   * @Readme
   */
  isEmptyObj(obj) {
    for (var i in obj) {
      if (obj.hasOwnProperty(i)) {
        return false; //非空
      }
    }
    return true; //空
  },

  /*
   * @Name 序列化url
   * @Param
   * @Return
   * @Author
   * @Readme
   */
  /* eslint-disable */
  parseURL: function (url) {
    var a = document.createElement("a");
    a.href = url;
    return {
      source: url,
      protocol: a.protocol.replace(":", ""),
      host: a.hostname,
      port: a.port,
      query: a.search,
      params: (function () {
        var ret = {},
          seg = a.search.replace(/^\?/, "").split("&"),
          len = seg.length,
          i = 0,
          s;
        for (; i < len; i++) {
          if (!seg[i]) {
            continue;
          }
          s = seg[i].split("=");
          ret[s[0]] = s[1];
        }
        return ret;
      })(),
      hashParams: (function () {
        var ret = {},
          str = a.href;
        var num = str.indexOf("?");
        str = str.substr(num + 1);
        var arr = str.split("&");
        var len = arr.length;
        for (var i = 0; i < len; i++) {
          if (!arr[i]) {
            continue;
          }
          var s = arr[i].split("=");
          ret[s[0]] = decodeURI(s[1]);
        }
        return ret;
      })(),
      file: (a.pathname.match(/\/([^\/?#]+)$/i) || [, ""])[1],
      hash: a.hash.replace("#", ""),
      path: a.pathname.replace(/^([^\/])/, "/$1"),
      relative: (a.href.match(/tps?:\/\/[^\/]+(.+)/) || [, ""])[1],
      segments: a.pathname.replace(/^\//, "").split("/"),
    };
  },
  /* eslint-enable */

  /*
   * @Name 下载csv
   * @Param
   * @Return
   * @Author
   * @Readme
   */
  /* eslint-disable */
  downloadCsv: function (linkId, header, content, fileName) {
    if (!linkId || !header || !content) {
      console.log("downloadCsv 参数 error");
      return false;
    }

    var linkId = linkId;
    var header = header; // ['ID','名字','职位','备注'];
    var content = content; // [['1','lemon','FE','啦啦啦'],['2','lara','str','啊啊啊'],['3','nolan','FF','噜噜噜']];
    var downloadLink = document.getElementById(linkId);
    var context = "";
    var context = header.join(",") + "\n";
    for (var i = 0; i < content.length; i++) {
      var item = content[i];
      item.forEach(function (item, index, list) {
        context = context + item + ",";
      });
      context = context + "\n";
    }

    // console.log('---------------拼接的字符串---------------\n' + context)
    //var context = "col1," + "反反\r复复" + ",col3\nvalue1,value2,value3"
    context = encodeURIComponent(context);
    downloadLink.download = fileName + ".csv"; // 下载的文件名称
    downloadLink.href = "data:text/csv;charset=utf-8,\ufeff" + context; //加上 \ufeff BOM 头
    downloadLink.addEventListener(
      "click",
      function (e) {
        e.stopPropagation();
      },
      false
    ); // 阻止冒泡事件

    // setTimeout(function(){
    // console.log(downloadLink.href)
    downloadLink.click();
    // },5000);
    console.log("下载csv文件完成！");
  },
  /* eslint-enable */

  /*
   * @Name ant-table 模糊查询
   * @Param
   * @Return
   * @Author
   * @Readme
   */
  fuzzySearch: function (queryKeyWord, originData) {
    let mySelf = this;

    if (queryKeyWord === undefined || !originData) {
      return false;
    }

    if (queryKeyWord === "") {
      return originData;
    }

    let newData = originData.filter(function (item, index, array) {
      // console.log(item.name)
      for (let key in item) {
        let value = item[key];
        let valueFormat = "";

        if (key === "id") {
          continue;
        }

        if (typeof value === "number") {
          valueFormat = value.toString();
        } else if (typeof value === "object") {
          valueFormat = mySelf.jsonStringify(value);
        } else if (typeof value === "string") {
          valueFormat = value;
        } else {
          continue;
        }

        // console.log(typeof item[key]);
        // console.log(key + ':' + typeof item[key]);
        if (valueFormat.includes(queryKeyWord)) {
          return item;
        }
      }
      return false;
    });
    return newData;
  },

  /*
   * @Name base64加密
   * @Param
   * @Return
   * @Author
   * @Readme
   */
  base64Encode: function (str) {
    if (str === undefined) {
      // console.log("非法入参");
      return false;
    }
    return window.btoa(unescape(encodeURIComponent(str)));
  },

  /*
   * @Name base64解密
   * @Param
   * @Return
   * @Author
   * @Readme
   */
  base64Decode: function (codeStr) {
    if (codeStr === undefined) {
      // console.log("非法入参");
      return false;
    }
    try {
      return decodeURIComponent(escape(window.atob(codeStr)));
    } catch (error) {
      return codeStr;
    }
  },

  /*
   * @Name 千分位
   * @Param
   * @Return
   * @Author
   * @Readme
   * 参考 https://segmentfault.com/a/1190000002884224
   * "1234567890.42545" 转化成 1,234,567,890.43
   */
  thousandStringFormat(num, digit) {
    digit = digit ? digit : 0;
    num = parseFloat(num); //转化成小数的形式
    //大于100的时候，再处理成千分位的格式
    if (num > 999) {
      num = num.toFixed(digit); //保留几位
      num = num.replace(/(^|\s)\d+/g, (m) =>
        m.replace(/(?=(?!\b)(\d{3})+$)/g, ",")
      ); //使用正则替换，每隔三个数加一个','
    }
    num = num.toString();
    return num;
  },

  /*
   * @Name 正则表达部分
   * @Param
   * @Return
   * @Author
   * @Readme
   */
  checkByRegular: function (type, value) {
    let REG = null;
    let valid = false;
    if (!type) {
      console.log("入参有误！");
    }

    //有空格 has_space
    if (type === "hasSpace") {
      REG = /\s+/;
    }
    //字段为空 is_empty
    else if (type === "isEmpty") {
      REG = /^$/;
    }
    //手机
    else if (type === "phone") {
      REG = /^(1[3-9][0-9])\d{8}$/;
    }
    //邮箱
    else if (type === "email") {
      REG = /^(\w)+([-_.\w])*@((\w)+[-.])+(\w){2,7}$/;
    }
    //密码
    else if (type === "password") {
      REG = /^[A-Za-z0-9~`!@#$%^&*()_\-+={}[\]|\\:;"'<>,.?/]{6,16}$/;
    }
    //正整数和0
    else if (type === "number") {
      REG = /^[1-9]\d*$|^0$/;
    }
    //正整数
    else if (type === "integerNumber") {
      REG = /^[1-9]\d*$/;
    }
    //正小数
    else if (type === "decimal") {
      REG = /^[1-9]\d*\.\d+$|^0\.\d+$/;
    }
    //正实数
    else if (type === "realNumber") {
      REG = /^[1-9]\d*(\.\d+)?$|^0(\.\d+)?$/;
    }
    //正在输入正实数 "123."这种输入中的是合法的
    else if (type === "inputingRealNumber") {
      REG = /^([0-9]{1,})+(\.[0-9]{0,})?$/;
    }
    //图形验证码
    else if (type === "graphCaptcha") {
      REG = /^[A-Za-z0-9]{4}$/;
    }
    //手机验证码
    else if (type === "phoneCaptcha") {
      REG = /^\d{6}$/;
    }
    //邮箱验证码
    else if (type === "emailCaptcha") {
      REG = /^[0-9]{6}$/;
    }
    //谷歌验证码
    else if (type === "ggValidator") {
      REG = /^[0-9]{6}$/;
    }
    //用户名
    else if (type === "userName") {
      REG = /^[A-Za-z0-9]{6,50}$/;
    }
    //姓 firstName
    else if (type === "firstName") {
      REG = /^[a-zA-Z]{1,50}$/;
    }
    //名 lastName
    else if (type === "lastName") {
      REG = /^[a-zA-Z]{1,50}$/; ///^\S{1,50}$/;
    }
    //证件号码
    else if (type === "identityNumber") {
      REG = /^[a-zA-Z0-9]{1,50}$/; ///^\S{1,50}$/;
    } else if (type === "int8AndDecimal8") {
      // precision_reg: Match floating point:  8 bit integers + . + 8 decimal
      REG = /^[1-9]\d{0,7}([.]\d{1,8})?$|^0(\.\d{1,8})?$/;
    }
    //数字&字母
    else if (type === "numberAndLetter") {
      REG = /^[a-zA-Z0-9]+$/;
    }
    //含有汉字
    else if (type === "includesChinese") {
      REG = /[\u4e00-\u9fa5]+/;
    }
    //银行卡号 纯数字
    else if (type === "bankAccount") {
      REG = /^\d+$/;
    } else {
      console.log("没找到匹配！");
      return false;
    }

    valid = REG.test(`${value}`);
    // console.log('valid', valid)
    if (!valid) {
      return false;
    }

    return true;
  },

  checkIsIp: function (value) {
    if (!value) {
      return false;
    }
    const regexp = /^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$/;
    let ipList = value.split(".");
    let valid = regexp.test(value);
    //首先必须是 xxx.xxx.xxx.xxx 类型的数字，如果不是，返回false
    if (!valid) {
      return false;
    }

    //第一个xxx不能为0
    if (Number(ipList[0]) === 0) {
      return false;
    }

    for (let i = 0; i < ipList.length; i++) {
      let ip = ipList[i];
      // console.log('当前校验字段 ' + ip)
      //大于255的不能通过
      if (Number.parseInt(ip) > 255) {
        return false;
      }
      //单个ip字节如果长度大于1位，开头不可以是'0'
      if (ip.length > 1 && ip.charAt(0) === "0") {
        return false;
      }
    }

    return true;
  },

  /*
   * @Name 判断当前环境
   * @Param
   * @Return
   * @Author
   * @Readme
   *
   */
  getCurrentEnv: function () {
    let env = "";
    let currentPath = window.location.href;
    switch (true) {
      case currentPath.includes("localhost"):
        env = "localhost";
        break;
      case currentPath.includes("dev"):
        env = "dev";
        break;
      case currentPath.includes("test"):
        env = "test";
        break;
      case currentPath.includes("pre"):
        env = "pre";
        break;
      default:
        env = "pro";
    }
    return env;
  },

  setAjaxRootDomain: function () {
    let mySelf = this;
    let currentEnv = mySelf.getCurrentEnv();
    let domain = "";
    if (currentEnv === "localhost") {
      domain = "";
    } else if (currentEnv === "dev") {
      // domain = $pars.devAjaxDomain;
      domain = "";
    } else if (currentEnv === "pro") {
      domain = "";
    }

    $pars.ajaxDomain = domain;
  },

  //重定向到节点之家
  judgeRedirectToBdfangzhou: function () {
    // let mySelf = this;
    let href = window.location.href;
    // console.log(href);
    if (
      href.includes("console.snodehome.com") ||
      href.includes("console.bdfangzhou.com")
    ) {
      window.location.href = "https://console.snodehome.cn/login";
    }
  },

  doGetFetch: function (options) {
    let {
      uri,
      credentials,
      successCallback,
      failedCallback,
      httpErrorCallback,
      selfCase = false,
    } = options;

    if (!uri) {
      console.log("入参有误;");
      return false;
    }

    /* credentials
     * omit 忽略
     * same-origin 同源请求带cookie
     * include 无论跨域还是同源请求都会带cookie
     */
    credentials = credentials || "include";
    let url = $pars.ajaxDomain + uri;
    let fetchrequest = fetch(url, {
      method: "GET",
      credentials: credentials,
      headers: {
        // 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        // 'Content-Type': 'charset=UTF-8',
        // 'x-request-id': 'f96561a5fa467d11d262daf2114b77cd',
      },
    })
      .then((res) => res.json())
      .then((res) => {
        // console.log(res);
        let { code, data } = res;
        //没有登录态
        if (code === 10001) {
          browserHistory.push("/login?forced=1");
        } else if (code === 0) {
          successCallback && successCallback(data);
        } else {
          if (!selfCase) {
            message.error(res.message || "请求出错");
          }
          failedCallback && failedCallback(res);
        }
        Promise.resolve(res);
      })
      //request error e.g 404 405
      .catch((err) => {
        console.log(err);
        httpErrorCallback && httpErrorCallback(err);
      });

    return fetchrequest;
  },

  doPostFetch: function (options) {
    let {
      uri,
      credentials,
      data,
      successCallback,
      failedCallback,
      httpErrorCallback,
      headers,
      selfCase = false,
    } = options;

    if (!uri) {
      console.log("入参有误;");
      return false;
    }

    credentials = credentials || "include";
    let url = $pars.ajaxDomain + uri;
    let defaultHeaders = {
      "Content-Type": "application/json;charset=utf-8",
    };
    defaultHeaders = { ...defaultHeaders, ...headers };
    // console.log(defaultHeaders);
    let postBody = JSON.stringify(data);
    let fetchrequest = fetch(url, {
      method: "POST",
      credentials: credentials,
      body: postBody,
      headers: defaultHeaders,
      // headers: {
      //     // 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      //     'Content-Type': 'application/json;charset=utf-8',
      //     // 'x-request-id': 'f96561a5fa467d11d262daf2114b77cd',
      // },
    })
      .then((res) => res.json())
      .then((res) => {
        // console.log(res);
        let { code, data } = res;
        //没有登录态
        if (code === 10001) {
          browserHistory.push("/login?forced=1");
        } else if (code === 0) {
          successCallback && successCallback(data);
        } else {
          if (!selfCase) {
            message.error(res.message || "请求出错");
          }
          failedCallback && failedCallback(res);
        }
        Promise.resolve(res);
      })
      //request error e.g 404 405
      .catch((err) => {
        console.log(err);
        httpErrorCallback && httpErrorCallback(err);
      });

    return fetchrequest;
  },

  /*
   * @Name 首字母小写
   * @Param
   * @Return
   * @Author
   * @Readme
   */
  firstLowerCase: function (str) {
    return str.replace(/\b(\w)(\w*)/g, function ($0, $1, $2) {
      return $1.toLowerCase() + $2;
    });
  },

  /*
   * @Name 首字母大写
   * @Param
   * @Return
   * @Author
   * @Readme
   */
  firstUpperCase: function (str) {
    return str.replace(/\b(\w)(\w*)/g, function ($0, $1, $2) {
      return $1.toUpperCase() + $2;
    });
  },

  /*
   * @Name stringTimeToUnixTimeStamp
   * @Param
   * @Return
   * @Author
   * @Readme 2018-01-03 15:19:53 => 1514963993000
   */
  stringTimeToUnixTimeStamp: function (timeStr) {
    if (!timeStr || typeof timeStr !== "string") {
      return false;
    }

    // console.log(timeStr)
    let timeObj = timeStr.replace(/-/g, "/");
    timeObj = new Date(timeObj);
    let timeStamp = timeObj.getTime();
    // console.log(date.getTime());
    return timeStamp;
  },

  /*
   * @Name 秒数时间格式化
   * @Param
   * @Return
   * @Author
   * @Readme 7201 => 2小时1秒
   */

  secondsTimeFormat(secdons, timeFormat = "") {
    let mySelf = this;
    secdons = Number.parseFloat(secdons);
    if (!Number.isFinite(secdons)) {
      console.log("secondsTimeFormat 报错 传入参数为非数字！");
      return false;
    }

    if (timeFormat === "" && secdons <= 0) {
      timeFormat = "0秒";
      return timeFormat;
    }

    if (secdons < 60) {
      let sec;
      sec = secdons > 0 ? secdons + "秒" : "";
      timeFormat = timeFormat + sec;
      return timeFormat;
    } else if (secdons < 3600) {
      let min;
      min = Math.floor(secdons / 60);
      //console.log(min)
      timeFormat = timeFormat + min + "分钟";
      return mySelf.secondsTimeFormat(secdons - min * 60, timeFormat);
    } else {
      let hour;
      hour = Math.floor(secdons / 3600);
      timeFormat = timeFormat + hour + "小时";
      return mySelf.secondsTimeFormat(secdons - hour * 3600, timeFormat);
    }
  },

  queryLocationPars() {
    let mySelf = this;
    let uri = "/api/miner_manage/miner/regionalConf";
    let successCallback = function (res) {
      let resData = res;
      let {
        isp: ispList,
        province: provinceList,
        city: cityList,
        net_type: netTypeList,
        web_port: webPortList,
        band_type: bandTypeList,
        miner_status: minerStatusList,
        province_city_map: provinceCityList,
      } = resData;
      let ispMap = {};
      let provinceMap = {};
      let cityMap = {};
      let netTypeMap = {};
      let webPortMap = {};
      let bandTypeMap = {};
      let minerStatusMap = {};
      let provinceCityOptions = [];

      ispList.forEach(function (item, index, array) {
        let { value, label } = item;
        ispMap[value] = label;
      });

      provinceList.forEach(function (item, index, array) {
        let { value, label } = item;
        provinceMap[value] = label;
      });

      cityList.forEach(function (item, index, array) {
        let { value, label } = item;
        cityMap[value] = label;
      });

      netTypeList.forEach(function (item, index, array) {
        let { value, label } = item;
        netTypeMap[value] = label;
      });

      webPortList.forEach(function (item, index, array) {
        let { value, label } = item;
        webPortMap[value] = label;
      });

      bandTypeList.forEach(function (item, index, array) {
        let { value, label } = item;
        bandTypeMap[value] = label;
      });

      minerStatusList.forEach(function (item, index, array) {
        let { value, label } = item;
        minerStatusMap[value] = label;
      });

      provinceCityList.forEach(function (item) {
        let { value: provinceValue, label: cityList } = item;

        let provinceName = provinceMap[provinceValue];
        let cityChildren = [];

        for (let i = 0; i < cityList.length; i++) {
          let cityObj = {};
          let unit = cityList[i];
          let cityName = cityMap[unit];
          if (!cityName) {
            continue;
          }
          cityObj = {
            value: unit,
            label: cityName,
          };
          cityChildren.push(cityObj);
        }

        let provinceObj = {
          value: provinceValue,
          label: provinceName,
          children: cityChildren,
        };

        provinceCityOptions.push(provinceObj);
      });

      // console.log(provinceCityOptions);
      $pars.ispList = ispList;
      $pars.ispMap = ispMap;
      $pars.provinceMap = provinceMap;
      $pars.cityMap = cityMap;
      $pars.netTypeMap = netTypeMap;
      $pars.webPortMap = webPortMap;
      $pars.bandTypeMap = bandTypeMap;
      $pars.minerStatusMap = minerStatusMap;
      $pars.provinceCityOptions = provinceCityOptions;
    };

    let options = {
      uri,
      successCallback,
    };

    return mySelf.doGetFetch(options);
  },

  querySuggestionConfig() {
    return this.doGetFetch({
      uri: "/api/miner_manage/feedback/config",
      successCallback: (data) => {
        const classMap = {};
        const typeMap = {};
        if (data.class) {
          data.class.forEach(({ type, value: val, label: lab }) => {
            classMap[val] = lab;
            if (type) {
              type.forEach(({ label, value }) => {
                typeMap[value] = label;
              });
            }
          });
        }
        $pars.suggestConfig = data;
        $pars.suggestClassifyMap = classMap;
        $pars.suggestTypeMap = typeMap;
      },
    });
  },

  doCopy(value) {
    let selection = window.getSelection();
    selection.removeAllRanges();
    let range = document.createRange();
    let el = document.createElement("span");
    // console.log(value);
    el.innerText = value;
    el.style.position = "absolute";
    el.style.right = "-9999px";
    //添加dom
    document.body.appendChild(el);
    range.selectNodeContents(el);
    selection.addRange(range);
    document.execCommand("copy", false, null);
    selection.removeAllRanges();
    //移除dom
    document.body.removeChild(el);
  },

  getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  },

  /*前端搜索例子*/
  /*let queryObj = {
        name: {
            value: "emo",
            rule: "fuzzy"
        },
        // name: {
        //     value: "12lemon34",
        //     rule: "equal"
        // },
        // name: {
        //     value: "emo",
        //     rule: "fieldNotInclude"
        // },
        // type: {
        //     value: ["1"],
        //     rule: "listInclude"
        // },
        type: {
            value: "2",
        },
    };

    let options = {
        originData,
        queryObj,
    };

    console.log(fieldsFuzzySearch(options))*/

  fieldsFuzzySearch(options) {
    // let mySelf = this;
    let { originData, queryObj } = options;

    let fieldContentStr = (fieldContent) => {
      if (typeof fieldContent === "object") {
        // console.log(fieldName);
        fieldContent = JSON.stringify(fieldContent);
      }
      return fieldContent;
    };

    let newData = originData.filter(function (item, index, array) {
      for (let fieldName in queryObj) {
        let keyWord = queryObj[fieldName]["value"];
        let rule = queryObj[fieldName]["rule"];
        let fieldContent = item[fieldName];
        // console.log(fieldContent)
        // console.log(item);
        // console.log(fieldName);
        // console.log(item.hasOwnProperty(fieldName));
        // console.log(keyWord);
        // console.log(fieldContent);
        // 为空
        if (keyWord === "") {
          continue;
        }

        //整条数据模糊查询
        if (rule && rule === "fuzzy") {
          const itemStr = JSON.stringify(item);
          // console.log(itemStr)
          // console.log(keyWord)
          // console.log(!itemStr.includes(keyWord))

          if (!itemStr.includes(String(keyWord))) {
            return false;
          }
        }
        //单个字段直等于
        else if (rule && rule === "equal" && fieldContent !== keyWord) {
          return false;
        }
        //单个字段不包含
        else if (rule && rule === "fieldNotInclude") {
          fieldContent = fieldContentStr(fieldContent);
          if (String(fieldContent).includes(keyWord)) {
            return false;
          }
        }
        //单个字段包含一个数组
        else if (rule && rule === "listInclude") {
          fieldContent = fieldContentStr(fieldContent);
          if (Array.isArray(keyWord) && !keyWord.includes(fieldContent)) {
            return false;
          }
        }
        //单个字段模糊查询
        else {
          fieldContent = fieldContentStr(fieldContent);
          // console.log(item);
          // console.log(fieldContent);
          //模糊匹配字段
          if (!String(fieldContent).includes(keyWord)) {
            return false;
          }
        }
      }
      return item;
    });

    return newData;
  },

  //根据value找key
  findKey(obj, value, compare = (a, b) => a === b) {
    return Object.keys(obj).find((k) => compare(obj[k], value));
  },

  //判断文件类型为图片
  isJpgOrPng(file) {
    if (!file) {
      return false;
    }

    let res = file.type === "image/jpeg" || file.type === "image/png";
    return res;
  },

  //出去所有空格
  replaceAllSpace(str) {
    return str.replace(/\s/g, "");
  },

  toFixed(value, length) {
    let num = Number(value);
    if (isNaN(num)) {
      return "--";
    } else {
      return num.toFixed(length || 1);
    }
  },

  // 生成唯一key值
  /* eslint-disable */
  createUniqueKey: function () {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  },
  /* eslint-enable */
};
