import { constants } from "../actions/user";

const defaultState = {
  bid: "",
};

export default (state = defaultState, action) => {
  switch (action.type) {
    // case constants.GET_USER_BID:
    //   return Object.assign({}, state, {
    //     loading: true,
    //   });
    case constants.USER_SAVE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
